<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-21 14:14:14
 * @LastEditTime: 2019-11-19 18:44:22
 * @LastEditors: Please set LastEditors
 -->
<!--  -->
<template>
  <div class="Supplier">
    <!-- <div class="banner"> -->
    <el-carousel class="banner">
      <div class="logo">
        <img style="width:80%;" :src="logo" alt/>
      </div>
      <section>
        <el-carousel-item v-for="item in banner" :key="item">
          <img :src="item" class="bannerImg"/>
        </el-carousel-item>
      </section>
    </el-carousel>
    <!-- </div> -->
    <div class="main">
      <div class="menu">
        <div class="category">
        </div>
        <div class="supplier">
          <el-collapse>
            <el-collapse-item>
              <template slot="title">
                <div class="title">
                  <span>{{ $t('restaurant_supplier_addSupProduct.16EBEC@price') }}</span>
                </div>
                <i class="header-icon"></i>
              </template>
              <div class="main">
                <div class="block">
                  <el-slider
                      @input="sliderChange"
                      v-model="screenPrice"
                      range
                      :max="500"
                      style="margin: 20px auto;"
                  ></el-slider>
                  <div class="input">
                    <el-input
                        type="number"
                        @input.native="$inputLenght"
                        @change="inputChange"
                        v-model="inputPrice[0]"
                        size="mini"
                    ></el-input>
                    <i class="el-icon-minus" style="margin-left: 5px;margin-right: 5px"></i>
                    <el-input
                        type="number"
                        @input.native="$inputLenght"
                        @change="inputChange"
                        v-model="inputPrice[1]"
                        size="mini"
                    ></el-input>
                    <el-button
                        type="danger"
                        plain
                        @click="filtrate"
                        size="mini"
                        style="margin-left: 10px"
                    >{{ $t('public.3B45A2@confirm') }}
                    </el-button>
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
          <div class="intro" style="background: #e9e9e9;padding: 2.5vh 1vw;font-weight: 1000">
            {{$t('supplier_company_information.65F497@company_description')}}：
            <div class="con" v-html="desc" style="font-weight: 400;padding-top: 1vh">{{desc}}</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="top">
          <div class="search">
            <i></i>
            <div class="search-input">
              <input type="text" v-model="keywords" :placeholder="$t('shop-goods.A29F44@search')"/>
              <button class="el-icon-search" @click="search"></button>
            </div>
          </div>
          <div class="block">
            <el-pagination
                class="pagination"
                @size-change="handleSizeChange"
                :page-sizes="[60, 120]"
                :page-size="pageSize"
                :pager-count="5"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
          </div>
        </div>
        <div class="label">
          <el-tag
              class="label-btn"
              v-for="tag in tagsList"
              :key="tag.name"
              closable
              @close="closeTag(tag.name)"
              :type="tag.type"
              disable-transitions
          >{{ tag.name }}
          </el-tag>
        </div>
        <div class="main-goods">
          <ul class>
            <li
                class="intro"
                v-for="(v,i) in goodslist"
                :key="i"
                @click.stop="selectSize(v)"
            >
              <div class="right">
                <span class="title">{{ v.name }}</span>
                <span v-if="v.display_name"
                      class="supplier">{{ $t('restaurant_buy_genner.586E4F@supplier_name') }}:{{
                    v.display_name
                  }}</span>
                <span v-else
                      class="supplier">{{ $t('restaurant_buy_genner.586E4F@supplier_name') }}:{{
                    v.supplier_name
                  }}</span>
                <span class="supplier">{{ $t('shop-index.6449D6@product_category') }}：{{ v.category }}</span>
                <span
                    class="supplier"
                >{{ $t('aboutus.72F5FF@Minimum_order_quantity') }}：{{ v.min_quantity }}</span>
                <span :class="v.rate? 'supplier':'place'">
                  {{
                    $t('supplier_supplier_produceManage.4D7AF5@Quantity_unit')
                  }}：{{ v.unit_str }}
                </span>
                <div class="price" v-show="priceShow">
                  <i></i>
                  <span v-if="v.special_price">
                    <del style="color:#606266">{{ v.price }}</del>
                    &nbsp;
                    <span>{{ v.special_price }}</span>
                  </span>
                  <span v-else>{{ v.price }}</span>
                </div>
              </div>
              <img :src="v.picture" alt v-if="v.picture"/>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <el-dialog
        center
        :append-to-body="true"
        custom-class="dialog-car"
        class
        :visible.sync="dialogVisible"
        :width="goodsData.picture?'55%':'50%'">
      <template slot="title">
        <div class="info-top">
          <div class="name">{{ $t('placeholder.F0DDC0@select') }}</div>
        </div>
      </template>
      <el-form :model="dialogForm" ref="form">
        <el-row type="flex" justify="center">
          <el-col v-if="goodsData.picture" :span="6" class="flex-cc">
            <img style="width:100%" :src="goodsData.picture" alt/>
          </el-col>

          <el-col :span="goodsData.picture?8:10" :offset="goodsData.picture?1:4">
            <h5
                style="font-weight: 500;margin-bottom: 20px;"
            >{{ $t('aboutus.72AB93@Product_Details') }}</h5>
            <el-form-item
                :label="$t('restaurant_buy_B2BProduct.6C52C8@product_name')"
            >:&nbsp;&nbsp;{{ goodsData.name }}
            </el-form-item>
            <el-form-item
                v-if="goodsData.special_price"
                :label="$t('restaurant_supplier_addSupProduct.16EBEC@price')"
            >
              :&nbsp;&nbsp;
              <del>{{ goodsData.price }}</del>&nbsp;
              <span style="color:#f24040">{{ goodsData.special_price }}</span>
            </el-form-item>
            <el-form-item
                v-else
                :label="$t('restaurant_supplier_addSupProduct.16EBEC@price')"
            >:&nbsp;&nbsp;{{ goodsData.price }}
            </el-form-item>
            <el-form-item
                v-show="!goodsData.sku_selector||goodsData.sku_selector.length==0"
                :label="$t('supplier_supplier_produceManage.4D7AF5@Quantity_unit')"
            >:&nbsp;&nbsp;{{ goodsData.unit_str }}
            </el-form-item>

            <el-form-item
                v-show="goodsData.unit_num&&goodsData.unit_name"
                :label="$t('supplier_supplier_produceManage.4D7AF5@Quantity_unit')"
            >:&nbsp;&nbsp;{{ goodsData.unit_num }}/{{ goodsData.unit_name }}
            </el-form-item>
            <el-form-item
                :label="v.spac_name+' :'"
                :prop="v.spac_name"
                v-for="(v, i) in goodsData.sku_selector"
                :key="i"
            >
              <el-radio-group v-model="radioArr[i]">
                <el-radio-button
                    :label="c.value_id"
                    v-for="(c, j) in v.spac_value"
                    :key="j"
                >{{ c.value_name }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item
                :label="$t('aboutus.72F5FF@Minimum_order_quantity')"
            >:&nbsp;&nbsp;{{ goodsData.min_quantity }}
            </el-form-item>
            <el-form-item
                v-if="goodsData.quantity_status==1"
                :label="$t('aboutus.E820CB@Estimated_delivery_time')"
            >
              :&nbsp;&nbsp;{{
                goodsData.delivery_time
              }}{{ $t('aboutus.D4D5D3@day') }}
            </el-form-item>
            <el-form-item
                v-else
                :label="$t('aboutus.E820CB@Estimated_delivery_time')"
            >:&nbsp;&nbsp;{{ $t('aboutus.F98ACA@Constant') }}
            </el-form-item>
          </el-col>
          <el-col :span="goodsData.picture?8:10">
            <h5
                style="font-weight: 500;margin-bottom: 20px;"
            >{{ $t('aboutus.3DB928@Supplier_Details') }}</h5>
            <el-form-item
                :label="$t('orderDetail.FF66B0@supplier')"
            >:&nbsp;
              <span v-if="goodsData.display_name">
                {{ goodsData.display_name }}
              </span>
              <span v-else>
                {{ goodsData.supplier_name }}
              </span>
              &nbsp;
            </el-form-item>
            <el-form-item
                :label="$t('shop-goods.88E57F@Minimum_order_amount')"
            >:&nbsp;&nbsp;{{ goodsData.amount_min }}
            </el-form-item>
            <el-form-item
                :label="$t('restaurant_supplier_supplierList.2E31F9@fee')"
            >:&nbsp;&nbsp;{{ goodsData.deliver_fee }}
            </el-form-item>
            <el-form-item
                :label="$t('shop-goods.E6DC79@Full_reduction')"
            >:&nbsp;&nbsp;{{ goodsData.full_slicing }}
            </el-form-item>
            <el-form-item
                :label="$t('aboutus.D11489@Order_Deadline')"
            >:&nbsp;&nbsp;{{ goodsData.cut_off_time }}
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="goodsData.description!==null" style="padding-bottom: 20px">
          <el-col :span="goodsData.picture?20:24"
                  :style="goodsData.picture?{'margin-left':'31.16667%'}:{'margin-left':'16.66667%'}">
            <el-form-item v-html="getDescription(goodsData.description)"
                          style="width: 80%;word-break: break-word;text-align:justify;text-justify:inter-ideograph;"></el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" justify="center">
          <el-col :span="goodsData.picture?22:24"
                  :style="goodsData.picture?{'margin-left':'31.16667%'}:{'margin-left':'16.66667%'}">
            <el-form-item :label="$t('shop_cart.D3B150@number')">
              :&nbsp;&nbsp;
              <el-input-number v-model="goodsform.num" :min="0"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <template slot="footer">
        <button
            class="add-car"
            @click="addOrder"
        >{{ $t('restaurant_buy_optionalOrder.118294@shopcart') }}
        </button>
        <div style="text-align:right;height:40px">
          <i class="fa fa-comments mr10" style="font-size:20px;cursor: pointer;" @click="goChat"></i>
          <i
              :class="['fa',{'fa-heart-o':!goodsData.is_favorite},{'fa-heart':goodsData.is_favorite}]"
              aria-hidden="true"
              @click="disposeFavoriteGoods(goodsData)"
              style="font-size:20px;color:#f24040;margin-right:12px;cursor: pointer;"
          ></i>

          <el-button
              type="info"
              size="mini"
              @click="openSample"
          >{{ $t('aboutus.B4F293@Request_sample') }}
          </el-button>
          <el-button
              type="info"
              size="mini"
              style="margin-right:20px;margin-top:20px;"
              @click="openOffer"
          >{{ $t('aboutus.375CE0@Request_a_special_offer') }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  name: "product",
  components: {},
  data() {
    //这里存放数据
    return {
      activeName: 0,
      currentPage: 1,
      pageSize: 60,
      total: 0,
      tags: [
        {name: "", type: "danger", class: "keywords"},
        {name: "", type: "warning", class: "inputPrice"},
      ],
      // 购物车弹框
      dialogVisible: false,
      num: 1,
      screenPrice: [0, 0],
      inputPrice: [],
      keywords: "",
      cid: "",
      goodsData: [],
      categorySelector: [],
      SupplierSelector: [],
      radioArr: [],
      radioArr1: [],
      radioArr2: [],
      dialogForm: {},
      priceShow: true,
      goodslist: [],
      goodsform: {
        gid: "",
        sku_id: "",
        num: 1,
      },
      sampleDialog: false,
      supplier_id: "",
      offerDialog: false,
      sampleForm: {
        sku_id: "",
        shop_id: "",
      },
      offerForm: {
        sku_id: "",
        shop_id: "",
        order_num: "",
        reorder: false,
      },
      outletList: [],
      banner: "",
      logo: "",
      desc: "",
    };
  },
  //监听属性 类似于data概念
  computed: {
    tagsList() {
      return this.tags.filter((item, index) => {
        if (item.name) {
          return item;
        }
      });
    },
  },
  //监控data中的数据变化
  watch: {
    radioArr: {
      handler: function (radioArr) {
        if (!this.goodsData.sku_selector[0]) return;
        let flag = true;
        radioArr.forEach((v, i) => {
          if (!radioArr[i]) {
            flag = false;
          }
        });
      },
      deep: true,
    },
  },
  //方法集合
  methods: {
    getPreviewProduct() {
      this.$http.getPreviewProduct({
        size: this.pageSize,
        keywords: this.keywords,
        start_price: this.inputPrice[0] || "",
        end_price: this.inputPrice[1] || "",
      }).then(res => {
        if (res.data.banner_list.length > 0) {
          this.banner = res.data.banner_list
        } else {
          this.banner = [require("../../assets/Supplier/banner.png")]
        }
        this.logo = res.data.logo
        this.desc = res.data.desc.replace(/\n/g, "<br/>")
        this.goodslist = res.data.list
        this.total = res.data.sum
      })
    },
    getDescription(d) {
      if (!d) return;
      return this.$t('restaurant_supplier_supplierList.E318B6@desc') + "&nbsp;&nbsp;:&nbsp;&nbsp;" + d.replace(/\n/g, "<br />");
    },
    // 加入购物车
    selectSize(row) {
      this.dialogVisible = true
      this.goodsData = Object.assign({}, row);
      this.goodsform.gid = row.gid;
      this.goodsData.sku_selector.forEach((v, i) => {
        this.$set(this.radioArr, i, "");
      });
    },
    // 确认
    addOrder() {
      this.dialogVisible = false
    },
    //获取库存
    getStock() {
      let params = {
        gid: this.goodsform.gid,
        value_json: JSON.stringify(this.radioArr),
      };
      this.$http.getB2BGoodsAttr(params).then((res) => {
        this.goodsData = Object.assign(this.goodsData, res.data);
        this.goodsform.sku_id = res.data.sku_id;
      });
    },
    // 点击搜索添加标签并搜索
    search() {
      this.tags[0].name = this.keywords;
      this.getPreviewProduct()
    },
    // 关闭标签并搜索
    closeTag(name) {
      this.tags.forEach((v, i) => {
        if (v.name == name) {
          v.name = "";
          if (i == 0) {
            this.keywords = "";
          } else if (i == 1) {
            this.inputPrice = [];
            this.screenPrice = [0, 0];
          }
        }
      });
      this.getPreviewProduct()
    },
    // 价格筛选并搜索
    filtrate() {
      this.tags[1].name = `${this.inputPrice[0]}~${this.inputPrice[1]}`;
      this.getPreviewProduct()
    },
    // 价格筛选输入框
    inputChange() {
      this.screenPrice = this.inputPrice;
    },
    // 价格筛选滑动条
    sliderChange() {
      let data = [...this.screenPrice];
      this.inputPrice = data;
    },
    // 打开索取样本弹框
    openSample() {
      this.dialogVisible = false
    },
    // 去聊天室
    goChat() {
      this.dialogVisible = false
    },
    // 打开索取特别价格弹框
    openOffer() {
      this.dialogVisible = false
    },
    // 加入我的最爱
    disposeFavoriteGoods(v) {
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getPreviewProduct()
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPreviewProduct()
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getPreviewProduct()
    // this.supplier_id = this.$route.query.Supplier_id;
    // this.getSupplierAdv();
    // this.getB2BGoodsList();
    // this.getSelector();
    // this.changeSelect();
    // this.getSupplierSelector();
    // this.priceShow = this.$getlocalStorage("token") ? true : false;
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  beforeCreate() {
  }, //生命周期 - 创建之前
  beforeMount() {
  }, //生命周期 - 挂载之前
  beforeUpdate() {
  }, //生命周期 - 更新之前
  updated() {
  }, //生命周期 - 更新之后
  beforeDestroy() {
  }, //生命周期 - 销毁之前
  destroyed() {
  }, //生命周期 - 销毁完成
  activated() {
  }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style src='./Product.scss' lang='scss' />
