<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-31 18:57:11
 * @LastEditTime: 2019-11-09 16:38:52
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="produce-manage">
    <page-top :title="$t('supplier_supplier_produceManage.4E60F8@Management_products')">
      <span class="fa fa-list fa-2x"></span>
    </page-top>
    <el-card>
      <section class="justify-between">
        <el-form :inline="true" :model="select" class="demo-form-inline">
          <el-form-item>
            <el-input
                style="width:220px;margin-right: 10px;"
                clearable
                @clear="clear"
                v-model="select.search"
                @input="getTableData"
                :placeholder="$t('placeholder.D20BE9@Please_input')"
            >
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-cascader
                v-model="select.cid"
                :options="typeOptions"
                :props="{
                children: 'sub_category',
                label: 'name',
                value: 'cid',
                emitPath: false,
                checkStrictly: true
              }"
                clearable
                @change="getCheckedNodes"
            ></el-cascader>
          </el-form-item>
        </el-form>
        <section class="flex-end flex">
          <el-dropdown @command="massUp" style="margin-right:20px;">
            <span class="el-dropdown-link" style="cursor:pointer;">
              {{ $t('aboutus.075530@massUp') }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">{{ $t('aboutus.B76508@showProduct') }}</el-dropdown-item>
              <el-dropdown-item command="0">{{ $t('aboutus.02B038@hidePRoduct') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <KtButton
              :label="$t('public.DC9752@btn_add')"
              style="height:35px"
              class="table-btn btn-gray"
              :perm="5869"
              @click="toAdd"
          ></KtButton>
        </section>
      </section>

      <el-table
          border
          :data="tableData"
          :row-class-name="$tableRowClassName"
          style="width: 100%;margin-top: 10px"
          v-loading="loading"
          :element-loading-text="$t('public.4ABAB1@loading')"
          @select="SelectSome"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
          @select-all="selectAll"
      >
        <el-table-column type="selection" align="center"></el-table-column>
        <el-table-column
            prop="gid"
            :label="$t('supplier_supplier_produceManage.C84B53@goods_ID')"
            align="center"
            width="100"
        ></el-table-column>
        <el-table-column
            prop="goods_no"
            :label="$t('aboutus.21AFC6@Product_No')"
            align="center"
            width="120"
        ></el-table-column>
        <el-table-column
            prop="category"
            :label="
            $t('supplier_supplier_produceManage.8D36FA@classification_of_goods')
          "
            align="center"
        ></el-table-column>
        <el-table-column
            prop="name"
            :label="$t('supplier_supplier_produceManage.B8BD33@product_name')"
            align="center"
        ></el-table-column>
        <!-- <el-table-column prop="picture" :label="$t('supplier_supplier_produceManage.ADD410@Commodity_images')"
          align="center" width="180">
          <template slot-scope="scope">
            <div style="width:100%;height:40px;">
              <img class="img-auto" v-if="scope.row.picture" :src="scope.row.picture" />
            </div>
          </template>
        </el-table-column>-->
        <el-table-column
            prop="price"
            :label="$t('supplier_supplier_produceManage.753635@price')"
            align="center"
            width="150"
        ></el-table-column>
        <el-table-column
            prop="unit_num"
            :label="$t('supplier_supplier_produceManage.4D7AF5@Quantity_unit')"
            align="center"
            header-align="center"
            width="130"
        >
          <template scope="scope">
            <div>{{ scope.row.unit_num }}/{{ scope.row.unit }}</div>
          </template>
        </el-table-column>
        <el-table-column
            prop="status"
            :label="$t('supplier_supplier_produceManage.72E6C1@putaway')"
            align="center"
            width="100"
        >
          <template slot-scope="scope">
            <span
                :style="{
                color: scope.row.status == 'YES' ? '#37B328' : '#F56C6C'
              }"
            >
              {{
                scope.row.status == 'YES'
                    ? $t('aboutus.980E0D@yes')
                    : $t('aboutus.A03514@no')
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
            prop="min_quantity"
            :label="$t('aboutus.72F5FF@Minimum_order_quantity')"
            align="center"
            width="110"
        ></el-table-column>
        <el-table-column
            prop="create_time"
            :label="$t('public.BFFB0F@creation_time')"
            align="center"
            show-overflow-tooltip
            width="150"
        ></el-table-column>
        <el-table-column
            prop="opration"
            :label="$t('public.03EA09@operation')"
            fixed="right"
            align="center"
            width="250"
        >
          <template slot-scope="scope">
            <KtButton
                :label="$t('public.B6D57F@btn_modify')"
                type="info"
                :perm="4907"
                @click="modify(scope.row.gid)"
            ></KtButton>
            <KtButton
                :label="$t('public.1FBB5E@btn_delete')"
                type="danger"
                :perm="1394"
                @click="deleteRow(scope.row.gid)"
            ></KtButton>
            <KtButton
                :label="$t('public.2AE651@preview')"
                type="primary"
                :perm="1394"
                @click="showPreview=true"
            ></KtButton>
          </template>
        </el-table-column>
      </el-table>
      <section class="pagination-container">
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[5, 10, 15]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </section>
      <div style="display: flex;">
        <KtButton
            :label="$t('public.97656C@Download_the_product_excel_template')"
            class="table-btn btn-gray"
            :perm="8517"
            @click="downloadexcel"
        ></KtButton>
        <!--      下載當前產品Excel  -->
        <KtButton
            :label="$t('aboutus.CAAF92@Download_bulk_edit_Excel')"
            class="table-btn btn-gray"
            :perm="7296"
            @click="downloadAwaitGoodsExcel"
        ></KtButton>
        <!--      上傳產品Excel  -->
        <el-upload
            accept=".xls,.xlsx"
            class="upload-demo ml10"
            action="no"
            :disabled="!hasPerms(16513269647282)"
            :http-request="uploadGoodsExcel">
          <KtButton
              :label="$t('public.93EC9B@upload')"
              class="table-btn btn-green"
              :perm="16513269647282">
          </KtButton>
        </el-upload>
        <!--      刪除所有供應商  -->
        <KtButton
            :label="$t('supplier_supplier_produceManage.B486AA@delete_all_products')"
            class="table-btn btn-red"
            :perm="1394"
            @click="deleteAllProduct">
        </KtButton>
      </div>
    </el-card>
    <el-dialog :visible.sync="showPreview" width="100%">
      <product></product>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../http/api";
import pageTop from "../../components/PageTop/PageTop";
import KtButton from "../../components/ktButton/KtButton";
import {hasPermission} from "../../permission/index.js";
import product from '../Preview/Product'
import {Message} from "element-ui";
import {getlocalStorage} from "../../utils/localStorage";


export default {
  data() {
    return {
      showPreview: false,
      select: {
        search: "",
        cid: 0,
        isShowPro: 1
      },
      typeOptions: [],
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      // 批量勾选的id
      idArr: [],
      loading: false
    };
  },

  components: {
    pageTop,
    KtButton,
    product,
  },
  created() {
    this.getTableData();
    this.getCategory();
  },
  methods: {
    //上傳產品Excel
    uploadGoodsExcel(param) {
      let that = this
      let reader = new FileReader()
      reader.readAsDataURL(param["file"])
      reader.onload = function (event) {
        that.$http.uploadGoodsExcel({
          file: this.result
        }).then(res => {
          if (res.errno == 0) {
            that.getTableData()
          } else if (res.errno == 3028) {
            Message.warning(res.msg)
          }
        })
      }
    },
    //刪除所有供應商
    deleteAllProduct() {
      this.$confirm(
          this.$t("shop-goods.2ECFD0@Confirm_delete"),
          this.$t("public.553159@tips"),
          {
            confirmButtonText: this.$t("public.3B45A2@confirm"),
            cancelButtonText: this.$t("public.836FD4@cancel")
          }
      ).then(() => {
        api.deleteAllProducts().then(res => {
          if (res.errno == 0) {
            this.getTableData()
          }
        })
      })
    },
    SelectSome(selection, row) {
      this.idArr = [];
      selection.forEach(item => {
        this.idArr.push(item.gid);
      });
    },
    selectAll(selection) {
      this.idArr = [];
      selection.forEach(item => {
        this.idArr.push(item.gid);
      });
    },

    massUp(val) {
      this.$http.massUp({
        goods_json: JSON.stringify(this.idArr),
        active: val
      }).then(res => {
        this.getTableData();
        this.idArr = [];
      });
    },
    getCategory() {
      this.$http.typeSelect({
        cid: 0
      }).then(res => {
        this.typeOptions = res.data;
      });
    },
    getCheckedNodes(val) {
      this.select.cid = val ? val : 0;
      this.getTableData();
    },
    hasPerms: function (perm) {
      return hasPermission(perm);
    },
    getTableData() {
      this.loading = true;
      api.getProduct({
        size: this.pageSize,
        page: this.currentPage,
        keywords: this.select.search,
        cid: this.select.cid
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.list;
        this.total = res.data.sum;
      });
    },
    searchGoods() {
      this.$http.searchCustomGoodsList({
        keywords: this.search,
        size: this.pageSize,
        page: this.currentPage
      }).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.sum;
      });
    },
    clear() {
      this.getTableData();
    },
    // 供应商下拉框筛选
    supplierChange(e) {
      this.supplier_id = e;
      this.getTableData();
    },
    toAdd() {
      this.$router.push({
        path: "/app/supplier/publishProduct",
        query: {
          id: 0
        }
      });
    },
    modify(id) {
      this.$router.push({
        path: "/app/supplier/publishProduct",
        query: {
          id: id
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    deleteRow(id) {
      this.$confirm(
          this.$t("shop-goods.2ECFD0@Confirm_delete"),
          this.$t("public.553159@tips"),
          {
            confirmButtonText: this.$t("public.3B45A2@confirm"),
            cancelButtonText: this.$t("public.836FD4@cancel")
          }
      ).then(() => {
        api.deleteProduct({
          gid: id
        }).then(res => {
          console.log("delete==>", res);
          if (this.tableData.length == 1 && this.currentPage > 1) {
            this.currentPage -= 1;
          }
          this.getTableData();
        });
      });
    },
    // 下载模板
    downloadexcel() {
      this.$http.downloadCustomGoodsTemplate().then(res => {
        this.downloadFile(res);
      });
    },
    //下载产品
    downloadSupplierExcels() {
      this.$http.downloadCustomGoodsExcel().then(res => {
        this.downloadFile(res);
      });
    },
    // 下載批量修改Excel
    downloadAwaitGoodsExcel() {
      this.$http.downloadAwaitGoodsExcel().then(res => {
        this.downloadFile(res);
      });
    },
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/app.scss";

.el-form-item {
  margin-bottom: 0;
}

.form-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  section {
    .el-select {
      margin-right: 20px;
    }
  }
}

.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  .pagination {
    float: right;
  }
}
</style>
